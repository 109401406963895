/* open-sans-300 - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	src: url('/themes/yamltheme/client/dist/fonts/open-sans-v35-latin_latin-ext-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('/themes/yamltheme/client/dist/fonts/open-sans-v35-latin_latin-ext-300.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* open-sans-300italic - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: italic;
	font-weight: 300;
	src: url('/themes/yamltheme/client/dist/fonts/open-sans-v35-latin_latin-ext-300italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('/themes/yamltheme/client/dist/fonts/open-sans-v35-latin_latin-ext-300italic.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* open-sans-regular - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('/themes/yamltheme/client/dist/fonts/open-sans-v35-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('/themes/yamltheme/client/dist/fonts/open-sans-v35-latin_latin-ext-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}

/* open-sans-600 - latin_latin-ext */
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	src: url('/themes/yamltheme/client/dist/fonts/open-sans-v35-latin_latin-ext-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('/themes/yamltheme/client/dist/fonts/open-sans-v35-latin_latin-ext-600.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
